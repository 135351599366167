import { ServiceButtonMode, ServiceButtonProps } from '../service-button-props';
import { useTranslation } from 'react-i18next';
import { useProjectBranchContext, useProjectBranchSettingsContext, useProjectBranchTermsContext } from '@context';
import { UsecaseButton } from '@lib/ui-components';
import {
  getServiceButtonConsts,
  infrastructureServiceFields,
  invoicingMethodControls,
  paymentMethodControls,
  priceFields,
  scheduleTypeControls,
  serviceValidationSchema,
  settlementTypeControls,
} from './shared';
import React from 'react';
import { ServiceType, SettlementType } from '@lib/api-interface';

export type SharedServiceButtonProps = ServiceButtonProps & { type: ServiceType.SCHEDULED | ServiceType.PER_REQUEST };

export const ServiceButton = (props: SharedServiceButtonProps) => {
  const { t } = useTranslation();
  const projectBranch = useProjectBranchContext();
  const projectBranchTerms = useProjectBranchTermsContext();
  const { label, useCaseName, startIcon } = getServiceButtonConsts(t, props.serviceButtonMode);
  const projectBranchSettings = useProjectBranchSettingsContext();

  function isResourceInBulk(data: any): boolean {
    return data.resourceInBulk === true;
  }

  function isNoPackage(data: any): boolean {
    return data.resourceInBulkNoPackage === true;
  }

  const initialService = props.serviceButtonMode !== ServiceButtonMode.CREATE ? props?.service : undefined;
  const hiddenValues =
    props.serviceButtonMode === ServiceButtonMode.EDIT
      ? {
          serviceId: initialService!.serviceId,
        }
      : {
          projectBranchId: projectBranch.projectBranchId,
          companyBranchId: projectBranch.branchId,
          projectId: projectBranch.projectId,
          acceptanceState: 'INITIALIZED',
          serviceType: props.type,
        };

  const prices =
    props.serviceButtonMode !== ServiceButtonMode.EDIT
      ? [
          ...priceFields('clientTransportPrice', t, t('clientTransport'), {
            initialService: initialService,
            defaultPrice: undefined,
            defaultType: initialService?.currentSettings?.clientTransportPrice?.priceType ?? 'UNIT',
            defaultBearer: initialService?.currentSettings?.clientTransportPrice?.bearer ?? 'ORGANIZATION_REVENUE',
            fixedPriceType: (data) => !data.pix,
            fixedBearer: true,
            overrideTypeSelectOptions: [
              { label: t('priceType.UNIT'), value: 'UNIT' },
              { label: t('priceType.MG'), value: 'MG' },
            ],
            type: initialService?.serviceType || props.type,
          }),
          ...priceFields('subcontractorTransportPrice', t, t('subcontractorTransport'), {
            initialService: initialService,
            defaultPrice: undefined,
            defaultType: initialService?.currentSettings?.subcontractorTransportPrice?.priceType ?? 'UNIT',
            defaultBearer: initialService?.currentSettings?.subcontractorTransportPrice?.bearer ?? 'ORGANIZATION_COST',
            fixedPriceType: (data) => !data.pix,
            fixedBearer: true,
            overrideTypeSelectOptions: [
              { label: t('priceType.UNIT'), value: 'UNIT' },
              { label: t('priceType.MG'), value: 'MG' },
            ],
            type: initialService?.serviceType || props.type,
          }),
          ...priceFields('clientCollectionPrice', t, t('clientCollection'), {
            initialService: initialService,
            defaultPrice: undefined,
            defaultType: initialService?.currentSettings?.clientCollectionPrice?.priceType ?? 'MG',
            defaultBearer: initialService?.currentSettings?.clientCollectionPrice?.bearer ?? 'ORGANIZATION_REVENUE',
            navResourceVisible: () => true,
            isVisible: (data) => !data.pix,
            type: initialService?.serviceType || props.type,
          }),
          ...priceFields('subcontractorCollectionPrice', t, t('subcontractorCollection'), {
            initialService: initialService,
            defaultPrice: undefined,
            defaultType: initialService?.currentSettings?.subcontractorCollectionPrice?.priceType ?? 'MG',
            defaultBearer: initialService?.currentSettings?.subcontractorCollectionPrice?.bearer ?? 'ORGANIZATION_COST',
            navResourceVisible: () => true,
            type: initialService?.serviceType || props.type,
          }),
          ...priceFields('loadingTimeClientPrice', t, t('loadingTimeClientPrice'), {
            initialService: initialService,
            defaultPrice: undefined,
            defaultType: 'HOUR',
            fixedPriceType: () => true,
            overrideTypeSelectOptions: [{ label: t('priceType.HOUR'), value: 'HOUR' }],
            defaultBearer: initialService?.currentSettings?.loadingTimeClientPrice?.bearer ?? 'ORGANIZATION_REVENUE',
            isVisible: (data) => data.showLoadingPrices,
          }),
          ...priceFields('loadingTimeSubcontractorPrice', t, t('loadingTimeSubcontractorPrice'), {
            initialService: initialService,
            defaultPrice: undefined,
            defaultType: 'HOUR',
            fixedPriceType: () => true,
            overrideTypeSelectOptions: [{ label: t('priceType.HOUR'), value: 'HOUR' }],
            defaultBearer: initialService?.currentSettings?.loadingTimeSubcontractorPrice?.bearer ?? 'ORGANIZATION_COST',
            isVisible: (data) => data.showLoadingPrices,
          }),
          ...priceFields('feesClientPrice', t, t('feesClientPrice'), {
            initialService: initialService,
            defaultPrice: undefined,
            defaultBearer: initialService?.currentSettings?.feesClientPrice?.bearer ?? 'ORGANIZATION_REVENUE',
            priceTypeVisible: () => false,
            isVisible: (data) => data.showFeesPrices,
            withCommentField: true,
          }),
          ...priceFields('feesSubcontractorPrice', t, t('feesSubcontractorPrice'), {
            initialService: initialService,
            defaultPrice: undefined,
            defaultBearer: initialService?.currentSettings?.feesSubcontractorPrice?.bearer ?? 'ORGANIZATION_COST',
            priceTypeVisible: () => false,
            isVisible: (data) => data.showFeesPrices,
            withCommentField: true,
          }),
          ...priceFields('fireProtectionClientPrice', t, t('fireProtectionClientPrice'), {
            initialService: initialService,
            defaultPrice: undefined,
            defaultType: 'MG',
            fixedPriceType: () => true,
            defaultBearer: initialService?.currentSettings?.fireProtectionClientPrice?.bearer ?? 'ORGANIZATION_REVENUE',
            isVisible: (data) => data.showFireProtectionPrices,
          }),
          ...priceFields('fireProtectionSubcontractorPrice', t, t('fireProtectionSubcontractorPrice'), {
            initialService: initialService,
            defaultPrice: undefined,
            defaultType: 'MG',
            fixedPriceType: () => true,
            defaultBearer: initialService?.currentSettings?.fireProtectionSubcontractorPrice?.bearer ?? 'ORGANIZATION_COST',
            isVisible: (data) => data.showFireProtectionPrices,
          }),
        ]
      : [];

  return (
    <UsecaseButton
      dataTestId={`service-button-${props.serviceButtonMode}`}
      maxWidth='lg'
      buttonLabel={label}
      dialogTitle={label}
      startIcon={startIcon}
      groupName='service'
      useCaseName={useCaseName}
      fields={[
        {
          rowItems: [
            {
              label: t('wasteCode'),
              type: 'table-select',
              field: 'projectWasteId',
              defaultValue: initialService?.projectWaste?.projectWasteId,
              tableSelectParams: {
                selectedValueField: 'projectWasteId',
                modelName: 'projectWasteViews',
                dialogTitle: t('wasteCode'),
                displayFormat: '{waste.code} - {waste.description}',
                fetchFilters: {
                  projectId: projectBranch.projectId,
                },
                columns: [
                  {
                    key: 'waste.code',
                    label: t('wasteCode'),
                    type: 'text',
                  },
                  {
                    key: 'waste.description',
                    label: t('wasteDescription'),
                    type: 'text',
                  },
                  {
                    key: 'projectWasteDescription',
                    label: t('ownDescriptionOfWaste'),
                    type: 'text',
                  },
                  {
                    key: 'waste.gtin',
                    label: t('gtin'),
                    type: 'numeric',
                  },
                ],
              },
            },
            {
              label: t('pix'),
              type: 'boolean',
              field: 'pix',
              defaultValue: initialService?.currentSettings?.pix,
            },
            {
              label: t('resourceInBulk'),
              type: 'boolean',
              field: 'resourceInBulk',
              defaultValue: initialService?.resourceInBulk,
            },
            {
              label: t('noPackage'),
              type: 'boolean',
              field: 'resourceInBulkNoPackage',
              defaultValue: initialService?.resourceInBulk === true && initialService?.collectionInfrastructureServiceId == null,
              isVisible: (data) => isResourceInBulk(data) && [ServiceType.PER_REQUEST, ServiceType.SCHEDULED].includes(props.type),
            },
          ],
        },
        {
          rowItems: infrastructureServiceFields(t, {
            service: initialService,
            projectBranchId: projectBranch.projectBranchId!,
            resource: true,
            label: t('resourceName'),
            idField: 'projectInfrastructureServiceId',
            countField: 'infrastructureCount',
            countDefaultValue: 1,
            hideCountField: () => true,
            isCommune: false,
            isVisible: (data) => !isResourceInBulk(data),
          }),
        },
        {
          rowItems: infrastructureServiceFields(t, {
            service: initialService,
            projectBranchId: projectBranch.projectBranchId!,
            collectionMethod: true,
            label: t('collectionMethod'),
            idField: 'collectionInfrastructureServiceId',
            countField: 'collectionInfrastructureCount',
            hideCountField: (data) => !data.collectionInfrastructureServiceId,
            isCommune: false,
            isVisible: (data) => !isNoPackage(data),
          }),
        },
        {
          rowItems: [
            {
              label: t('activationDate'),
              type: 'date',
              field: 'activationDate',
              defaultValue: props.serviceButtonMode === ServiceButtonMode.EDIT ? initialService?.activationDate : undefined,
            },
            {
              label: t('deactivationDate'),
              type: 'date',
              field: 'deactivationDate',
              defaultValue: props.serviceButtonMode === ServiceButtonMode.EDIT ? initialService?.deactivationDate : undefined,
            },
          ],
        },
        {
          rowItems: [
            {
              label: t('constantCost'),
              type: 'boolean',
              field: 'constantCost',
              defaultValue: initialService?.constantCost,
              isVisible: () => props.type === ServiceType.SCHEDULED,
            },
            {
              label: t('organizationAsSide'),
              type: 'boolean',
              field: 'organizationAsSide',
              defaultValue:
                initialService?.currentSettings?.organizationAsSide === undefined
                  ? projectBranchSettings?.organizationIsParticipating
                  : initialService?.currentSettings?.organizationAsSide,
              isVisible: () => props.serviceButtonMode !== ServiceButtonMode.EDIT,
            },
          ],
        },
        {
          rowItems: [
            {
              label: t('includeLoadingPrices'),
              type: 'boolean',
              field: 'showLoadingPrices',
              isVisible: () => props.serviceButtonMode !== ServiceButtonMode.EDIT,
              defaultValue:
                initialService?.currentSettings?.loadingTimeClientPrice?.price !== undefined || initialService?.currentSettings?.loadingTimeSubcontractorPrice?.price !== undefined,
            },
            {
              label: t('includeFeesPrices'),
              type: 'boolean',
              field: 'showFeesPrices',
              isVisible: () => props.serviceButtonMode !== ServiceButtonMode.EDIT,
              defaultValue: initialService?.currentSettings?.feesClientPrice?.price !== undefined || initialService?.currentSettings?.feesSubcontractorPrice?.price !== undefined,
            },
            {
              label: t('includeFireProtectionPrices'),
              type: 'boolean',
              field: 'showFireProtectionPrices',
              isVisible: () => props.serviceButtonMode !== ServiceButtonMode.EDIT,
              defaultValue:
                initialService?.currentSettings?.fireProtectionClientPrice?.price !== undefined ||
                initialService?.currentSettings?.fireProtectionSubcontractorPrice?.price !== undefined,
            },
          ],
        },
        ...prices,
        {
          rowItems: [
            {
              label: t('settledSubcontractorName'),
              type: 'table-select',
              field: 'subcontractorId',
              defaultValue: initialService?.subcontractorId,
              margin: '0 0 1rem 0',
              tableSelectParams: {
                modelName: 'companyBranchViews',
                search: 'forSettledSubcontractor',
                dialogTitle: t('subcontractor'),
                displayFormat: '{name}',
                selectedValueField: 'branchId',
                columns: [
                  {
                    label: t('name'),
                    key: 'name',
                    type: 'text',
                  },
                  {
                    label: t('town'),
                    key: 'registrationAddress.town',
                    type: 'text',
                  },
                  {
                    label: t('vatin'),
                    key: 'vatin',
                    type: 'text',
                  },
                ],
              },
            },
            {
              label: t('reactionTime'),
              type: 'numeric',
              field: 'reactionTime',
              defaultValue: initialService?.reactionTime || (props.type === ServiceType.PER_REQUEST ? 1 : undefined),
              width: '10rem',
              isVisible: () => props.type === ServiceType.PER_REQUEST,
            },
          ],
        },
        {
          label: t('settledTransportEntity'),
          type: 'table-select',
          field: 'transporterId',
          defaultValue: initialService?.transporterId,
          tableSelectParams: {
            modelName: 'companyBranchViews',
            search: 'forSettledTransporter',
            dialogTitle: t('transportEntity'),
            displayFormat: '{name}',
            selectedValueField: 'branchId',
            columns: [
              {
                label: t('name'),
                key: 'name',
                type: 'text',
              },
              {
                label: t('town'),
                key: 'registrationAddress.town',
                type: 'text',
              },
              {
                label: t('vatin'),
                key: 'vatin',
                type: 'text',
              },
            ],
          },
        },
        ...(props.type === ServiceType.SCHEDULED ? scheduleTypeControls(t, initialService) : []),
        {
          rowItems: [
            settlementTypeControls(
              t,
              props.serviceButtonMode === ServiceButtonMode.CREATE
                ? projectBranchTerms.aggregatingSettlement
                  ? SettlementType.PER_MONTH
                  : SettlementType.PER_COLLECTION
                : initialService?.settlementType,
            ),
            {
              label: t('logisticMinimumSurcharge'),
              type: 'text',
              field: 'logisticMinimumSurcharge',
              defaultValue: initialService?.logisticMinimumSurcharge,
            },
          ],
        },
        {
          rowItems: [
            paymentMethodControls(t, initialService?.paymentMethod || projectBranchTerms.paymentMethod),
            {
              label: t('separatedInvoice'),
              type: 'boolean',
              field: 'separateInvoice',
              defaultValue: initialService?.separateInvoice,
            },
            invoicingMethodControls(t, initialService?.invoicingMethod || projectBranchTerms.invoicingMethod),
          ],
        },
      ]}
      hiddenValues={hiddenValues}
      onBeforeSave={(formData) => {
        delete formData.selectedInfrastructureCategory;
        delete formData.selectedProjectInfrastructureId;
        if (formData.resourceInBulk === true) {
          delete formData.projectInfrastructureServiceId;
          delete formData.infrastructureCount;
        }
        if (formData.onDemand) {
          delete formData.scheduleType;
          delete formData.staticCalendarSchedule;
          delete formData.dynamicCalendarSchedule;
          delete formData.activeFromDate;
        }
        if (props.serviceButtonMode !== ServiceButtonMode.EDIT) {
          if (formData.clientTransportPrice.price == '') {
            formData.clientTransportPrice.price = '0.0';
          }
          if (formData.subcontractorTransportPrice.price == '') {
            formData.subcontractorTransportPrice.price = '0.0';
          }
        }
        if (formData.resourceInBulkNoPackage) {
          delete formData.collectionInfrastructureServiceId;
          formData.collectionInfrastructureCount = 1;
        }
        return formData;
      }}
      onSaved={async () => {
        if (props.onSaved) {
          props.onSaved();
        }
        if (props.serviceButtonMode !== ServiceButtonMode.EDIT) props.reloadServices();
      }}
      validationSchema={serviceValidationSchema(t, props.serviceButtonMode === ServiceButtonMode.EDIT, props.type)}
    />
  );
};
